const PROD_URL = "https://gravl-backend-prod.gravl.io";
const DEV_URL = "https://gravl-backend-dev.gravl.io";
const DEMO_URL = "https://gravl-backend-demo.gravl.io";

export const BACKEND_URL =
  process.env.REACT_APP_ENV === 'DEV'
    ? DEV_URL
    : process.env.REACT_APP_ENV === 'DEMO'
      ? DEMO_URL
      : process.env.REACT_APP_ENV === 'PROD'
        ? PROD_URL
        : 'http://localhost:80';
